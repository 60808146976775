import { ButtonProps, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { signOut } from 'next-auth/react';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAxiosResponseErrorObject } from '@/lib';
import { useToast } from '@/hooks/useToast';

import { Button } from '@/components/UI/Buttons/Button';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { ErrorCode } from '@/models/api/Error';
import { AddToCartProps, ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { BasketIcon } from '../Icons';
import { useApp } from '../../../context/appContext';

const AddToCart = (props: AddToCartProps & ButtonProps) => {
    const { orderingAllowed, showDisabledOrderingPopup } = useApp();
    const { code, quantity, setQuantity, icon, maxOrder, ...rest } = props;
    const { increaseItemQuantity, cartItems, disableCartActions } = useShoppingCart();
    const { errorToast, infoToast, successToast } = useToast();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    const obj = useMemo(
        () => ({ code, quantity, setQuantity, icon, maxOrder }),
        [code, quantity, setQuantity, icon, maxOrder]
    );

    const addToCart = async (id: ProductIdProps) => {
        if (!orderingAllowed) {
            showDisabledOrderingPopup();
            return;
        }
        setIsLoading(true);
        if (maxOrder !== undefined && quantity > maxOrder) {
            infoToast({
                description: intl.formatMessage(
                    {
                        id: 'basket.reached-max-order'
                    },
                    { maxItems: maxOrder.toString() }
                )
            });
            setIsLoading(false);
            return;
        }
        if (cartItems.length < (BasketConfig.pageSize as number)) {
            try {
                await increaseItemQuantity(id, obj.quantity);

                setQuantity?.(1);

                successToast({
                    title: intl.formatMessage({ id: 'added-to-cart' })
                });
            } catch (e) {
                const error = getAxiosResponseErrorObject(e);
                const title = intl.formatMessage({
                    id: 'add-to-cart.update-product-quantity.fail'
                });
                const description =
                    error?.errors?.length > 0
                        ? intl.formatMessage({ id: error.errors?.[0].code }) ||
                          error.errors?.[0].code
                        : '';

                errorToast({
                    title,
                    description
                });

                if (error?.errors?.[0]?.code === ErrorCode.disabled) {
                    disableCartActions(true);
                }

                if (error?.errors?.[0]?.code === ErrorCode.unauthenticated) {
                    signOut({
                        callbackUrl: RoutePath.Home
                    });
                }
            }

            setIsLoading(false);

            return;
        }

        setIsLoading(false);

        errorToast({
            title: intl.formatMessage({ id: 'add-to-cart.max-page-size.title' }),
            description: intl.formatMessage({ id: 'add-to-cart.max-page-size.description' })
        });
    };

    return (
        <Button
            onClick={() => addToCart(obj.code)}
            display="flex"
            justifyContent="center"
            minW={28}
            px={4}
            colorScheme="blue"
            borderRadius="full"
            h="52px"
            disabled={!orderingAllowed}
            isLoading={isLoading}
            {...rest}
        >
            <Flex
                as="span"
                fontWeight="semibold"
                alignItems="center"
                justifyContent="center"
                pointerEvents="none"
            >
                <>{icon || <BasketIcon pointerEvents="none" h="20px" w="auto" />}</>
                <Text ml={2} fontWeight="bold">
                    <FormattedMessage id="add-to-cart" />
                </Text>
            </Flex>
        </Button>
    );
};

export default AddToCart;
